const DOMAIN = {
  DEV: 'https://edelvivesdigital-dev.oneclicklabs.es/',
  QA: 'https://edelvivesdigital-qa.oneclicklabs.es/',
  PROD: 'https://edelvivesdigitalplus.com/',
};

const BASE_DEV = DOMAIN.PROD;

const BASE_AGNOSTIC = window.location.origin.includes('localhost') ? BASE_DEV : window.location.origin + '/';

export const defaultConfig = {
  BASE_URL_: BASE_AGNOSTIC,
  API_URL: BASE_AGNOSTIC + 'api/',
  BASE_STORAGE_URL: BASE_AGNOSTIC + 'files-storage-tools/',
};
