import PropTypes from 'prop-types';
import React from 'react';
import CoreImage from '../../../variables/images';

const Image = ({ id, className }) => {
  return <img className={className} src={CoreImage.getImage(id)} alt="" />;
};

Image.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOf([
    'logoBig',
    'logoIconS',
    'noBooks',
    'noItems',
    'noUsers',
    'noQuestions',
    'noCourses',
    'arrow-orange',
    'barMenuLogo',
    'logoWhite',
    'noPictureProgram',
    'cover-card-task',
    'cover-card-out-of-time',
    'cover-card-quizz',
  ]),
};

Image.defaultProps = {
  id: 'logoBig',
  className: 'image',
};

export default Image;
