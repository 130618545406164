import { ROUTES } from 'app/utils/constants/paths';
import React from 'react';
import { isTeams } from '../../_core/utils/teamsTab';

import DemoLoading from 'app/pages/DemoLandingPage/DemoLoading';
// import LogoutPage from '../modules/containers/Logout';
import AboutView from '../modules/views/AboutView/AboutView';
import SupportView from '../modules/views/SupportView/SupportView';
import PrivacyPolicy from '../pages/StaticPages/PrivacyPolicy';
import TermsOfUse from '../pages/StaticPages/TermsOfUse';
import TabAuthEnd from '../pages/Teams/TabAuthEnd';
import TabAuthStart from '../pages/Teams/TabAuthStart';
import LoginPage from '../pages/auth/Login/LoginPage';
import SignUpPage from '../pages/auth/SignUp/SignUpPage';

const ErrorView = React.lazy(() => import('app/modules/views/ErrorView/ErrorView'));
const HomePage = React.lazy(() => import('app/pages/HomePage/HomePage'));
const KanbanPage = React.lazy(() => import('app/pages/KanbanPage/KanbanPage'));
const CoursePage = React.lazy(() => import('app/pages/CoursePage/CoursePage'));
const LessonView = React.lazy(() => import('_core/lite/views/LessonView/LessonView/LessonView'));
const CustomLessonView = React.lazy(() => import('app/modules/views/CustomLessonView/CustomLessonView'));
const CustomLessonViewToDigest = React.lazy(() => import('app/modules/views/CustomLessonView/CustomLessonViewToDigest'));
const LogoutPage = React.lazy(() => import('app/modules/containers/Logout'));
const ToolsProjectContainer = React.lazy(() => import('_core/lite/views/ToolsProjectView/ToolsProjectContainer'));
const AnswerTest = React.lazy(() => import('../pages/AnswerTest'));
const AssessmentResult = React.lazy(() => import('../pages/AssessmentResultPage/AssessmentResult'));
const TokenCallback = React.lazy(() => import('../pages/auth/TokenCallback'));

const CalendarPage = React.lazy(() => import('../pages/CalendarPage/CalendarPage'));
const LemonTest = React.lazy(() => import('../pages/LemonTest'));
// const NewContentView = React.lazy(() => import('../pages/NewContent'));
const ProfilePage = React.lazy(() => import('../pages/ProfilePage/ProfilePage'));
const ProjectionMint = React.lazy(() => import('../pages/ProjectionMint'));
const Projection = React.lazy(() => import('../pages/Projection'));
const RemotePage = React.lazy(() => import('../pages/RemotePage'));
const Viewer = React.lazy(() => import('../pages/viewer'));
const AddLicense = React.lazy(() => import('../pages/AddLicensePage/AddLicensePage'));
const RecoverPasswordPage = React.lazy(() => import('../pages/RecoverPasswordPage/RecoverPasswordPage'));
const ClassroomUsersPage = React.lazy(() => import('../pages/ClassroomUsersPage/ClassroomUsersPage'));
const GroupResourcesPage = React.lazy(() => import('../pages/GroupResourcesPage/GroupResourcesPage'));
const MSTeamsConfiguration = React.lazy(() => import('../pages/MSTeamsConfiguration'));
const IndividualActivityPage = React.lazy(() => import('../pages/IndividualActivityPage/IndividualActivityPage'));
const DeepLinkView = React.lazy(() => import('_core/lite/views/DeepLinkView'));
const DialogDesktopVersion = React.lazy(() => import('_core/modules/components/dialogs/DialogDesktopVersion/DialogDesktopVersion'));
// const DemoLandingPage = React.lazy(() => import('app/pages/DemoEsoLandingPage/DemoEsoLandingPage'));
const CreateEvaluationsPage = React.lazy(() => import('../pages/CreateEvaluationsPage'));
const DoEvaluationPage = React.lazy(() => import('../pages/DoEvaluationPage'));
const ViewEvaluationPage = React.lazy(() => import('../pages/ViewEvaluationPage'));
const ResultsEvaluationPage = React.lazy(() => import('../pages/ResultsEvaluationPage'));
const UiPage = React.lazy(() => import('../pages/UiPage/UiPage'));
const ResourcesPage = React.lazy(() => import('app/pages/ResourcesPage/ResourcesPage'));
const TasksPage = React.lazy(() => import('app/pages/TasksPage'));
const AssessmentPage = React.lazy(() => import('app/pages/AssessmentPage'));
const ResetPasswordPage = React.lazy(() => import('../pages/ResetPasswordPage/ResetPasswordPage'));

export const externalRoutes = [
  { path: ROUTES.REMOTE_TOKEN, component: RemotePage },
  { path: ROUTES.DEEPLINK, component: DeepLinkView },
  { path: ROUTES.DEEPLINK_TOKEN, component: DeepLinkView },
  { path: ROUTES.ABOUT, component: AboutView },
  { path: ROUTES.SUPPORT, component: SupportView },
  /* Teams configuration is required regardless if authorized or not to be able to bypass the popup */
  { path: ROUTES.TEAMS_CONFIG, component: MSTeamsConfiguration },
  { path: ROUTES.TEAMS_TAB_AUTH_START, component: TabAuthStart },
  { path: ROUTES.TEAMS_TAB_AUTH_END, component: TabAuthEnd },
  /* Static pages */
  { path: ROUTES.PRIVACY, component: PrivacyPolicy },
  { path: ROUTES.TERMS, component: TermsOfUse },
  { path: ROUTES.LOGOUT, component: LogoutPage },
  { path: ROUTES.LOADING_DEMO, component: DemoLoading },
  { path: ROUTES.PRESCHOOL_RESOURCES, component: ResourcesPage },
];

export const publicRoutes = [
  { path: ROUTES.AUTH_LOGIN_TOKEN, component: TokenCallback },
  { path: ROUTES.AUTH_LOGIN, component: LoginPage },
  { path: ROUTES.AUTH_SIGNUP, component: SignUpPage },
  { path: ROUTES.RECOVER_PASSWORD, component: RecoverPasswordPage },
];

export const localhostRoutes = [
  { path: ROUTES.UI, component: UiPage },
  // { path: ROUTES.TEST_COMPONENT, component: TestComponentView },
];

export const privateRoutes = [
  { path: ROUTES.HOME, component: HomePage },
  { path: ROUTES.ASSESSMENT_QUESTION, component: LemonTest },
  { path: ROUTES.ASSESSMENT_RESULT, component: AssessmentResult },
  { path: ROUTES.ASSESSMENT, component: AssessmentPage },
  // { path: ROUTES.NEW_CONTENT, component: NewContentView },
  // { path: ROUTES.CONTENT, component: NewContentView },
  { path: ROUTES.PROJECTION_MINT, component: ProjectionMint },
  { path: ROUTES.PROJECTION, component: Projection },
  { path: ROUTES.LESSON, component: LessonView },
  { path: ROUTES.CUSTOM_LESSON_DIGEST, component: CustomLessonViewToDigest, props: { typeToFilterLesson: isTeams() ? 'mint' : null } },
  { path: ROUTES.CUSTOM_LESSON, component: CustomLessonView, props: { typeToFilterLesson: isTeams() ? 'mint' : null } },
  { path: ROUTES.CUSTOM_LESSON_UNIT, component: CustomLessonView, props: { typeToFilterLesson: isTeams() ? 'mint' : null } },
  { path: ROUTES.CREATE_EVALUATION, component: CreateEvaluationsPage },
  { path: ROUTES.EDIT_EVALUATION, component: CreateEvaluationsPage },
  { path: ROUTES.EVALUATION, component: DoEvaluationPage },
  { path: ROUTES.EVALUATION_VIEW_RESOURCES, component: ViewEvaluationPage },
  { path: ROUTES.EVALUATION_VIEW, component: ViewEvaluationPage },
  { path: ROUTES.EVALUATION_RESULTS_RESOURCES, component: ResultsEvaluationPage },
  { path: ROUTES.EVALUATION_RESULTS, component: ResultsEvaluationPage },
  { path: ROUTES.KANBAN_LESSON, component: KanbanPage },
  { path: ROUTES.COURSE_GRADES, component: CoursePage, props: { tabId: 'grades' } },
  { path: ROUTES.COURSE_PROGRAM, component: CoursePage, props: { tabId: 'program' } },
  { path: ROUTES.RESOURCES_PAGE, component: GroupResourcesPage },
  { path: ROUTES.COURSE_RESOURCES, component: CoursePage, props: { tabId: 'resources' } },
  { path: ROUTES.COURSE_RESOURCES_DEMO, component: CoursePage, props: { tabId: 'resources-demo' } },
  { path: ROUTES.COURSE, component: CoursePage, props: { tabId: 'stream' } },
  { path: ROUTES.VIEWER_FULL, component: Viewer, props: { showHeader: false } },
  { path: ROUTES.VIEWER, component: Viewer, props: { showHeader: true } },
  { path: ROUTES.VIEWER_FULL_GROUP, component: Viewer, props: { showHeader: false } },
  { path: ROUTES.VIEWER_GROUP, component: Viewer, props: { showHeader: true } },
  { path: ROUTES.CALENDAR, component: CalendarPage },
  { path: ROUTES.CALENDAR_COURSE, component: CalendarPage },
  { path: ROUTES.PROFILE, component: ProfilePage },
  { path: ROUTES.TASKS, component: TasksPage },
  { path: ROUTES.LEMON_TEST, component: LemonTest },
  { path: ROUTES.ANSWER_TEST, component: AnswerTest },
  { path: ROUTES.SINGLE_ACTIVITY_RECORD, component: IndividualActivityPage, props: { record: true } },
  { path: ROUTES.SINGLE_ACTIVITY, component: IndividualActivityPage },
  { path: ROUTES.CLASSROOM_USERS, component: ClassroomUsersPage },
  { path: ROUTES.TOOLS, component: ToolsProjectContainer },
  { path: ROUTES.REMOTE, component: RemotePage },
  { path: ROUTES.ERROR, component: ErrorView },
  { path: ROUTES.LICENSE, component: AddLicense },
  { path: ROUTES.DESKTOP, component: DialogDesktopVersion },
  { path: ROUTES.RESET_PASSWORD, component: ResetPasswordPage },

];
