/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */
import { InteractionStatus } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import * as microsoftTeams from '@microsoft/teams-js';
import { LayoutContextProvider, frontoffice, isEdelvivesMX, isInPublisher, isLocalhost, isNativeAppWebview } from '_core';
import { getApiHealth } from '_core/crud/appInfo.crud';
import * as auth from '_core/store/auth.duck';
import * as coreEntities from '_core/store/index';
import * as routerHelpers from '_core/utils/RouterHelpers';
import { isTeams } from '_core/utils/teamsTab';
import { isEditorial, isStudent, isTeacher } from '_core/utils/user';
import amplitude from 'amplitude-js';
import { clientIdAmplitude } from 'app/config/config';
import { ROUTES } from 'app/utils/constants/paths';
import { notificationsTypes } from 'app/utils/notifications';
import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { useLastLocation } from 'react-router-last-location';
import { defaultConfig } from '../config/environment';
import LoginPage from '../pages/auth/Login/LoginPage';
import SignUpPage from '../pages/auth/SignUp/SignUpPage';
import { externalRoutes, localhostRoutes, privateRoutes } from './routesConfig';

const NotFound = React.lazy(() => import('app/pages/ContentNotFoundPage/ContentNotFoundPage'));
const BarMenuContainer = React.lazy(() => import('app/containers/BarMenuContainer'));
const TokenCallback = React.lazy(() => import('../pages/auth/TokenCallback'));
const AddLicense = React.lazy(() => import('../pages/AddLicensePage/AddLicensePage'));
const ResetPasswordPage = React.lazy(() => import('../pages/ResetPasswordPage/ResetPasswordPage'));
const RecoverPasswordPage = React.lazy(() => import('../pages/RecoverPasswordPage/RecoverPasswordPage'));

export const APP_IS_PUBLISHER = isInPublisher(defaultConfig) || false;

let callbackToken = null;

export const Routes = withRouter(({ history }) => {
  const lastLocation = useLastLocation();
  const dispatch = useDispatch();
  const { inProgress } = useMsal();
  const [teamsContentUrl, setTeamsContentUrl] = useState('');
  const [isTeamsDark, setIsTeamsDark] = useState(false);

  checkIfCallbackTokenExists();

  const { isAuthorized, country, roleUser, license, isChangePassword } = useSelector(
    ({ auth }) => ({
      isAuthorized: callbackToken ? auth.user != null && auth.authToken === callbackToken : auth.user != null,
      roleUser: (auth.user && auth.user.role_guid) || null,
      country: auth.user && auth.user.country_guid ? auth.user.country_guid : 'es',
      license: auth.license,
      isChangePassword: Boolean(auth.changePassword),
    }),
    shallowEqual
  );

  const user = useSelector((state) => auth.selectors.getUser(state));
  const oauthAccounts = useSelector((state) => auth.selectors.getOauthAccounts(state));
  const lms = useSelector((state) => coreEntities.organization.selectors.getLms(state));
  const client = useSelector((state) => coreEntities.organization.selectors.getClient(state));
  // const tenant = useSelector((state) => coreEntities.organization.selectors.getTenant(state));

  useEffect(() => {
    if (isEdelvivesMX()) dispatch(coreEntities.i18n.actions.setLanguage('es-MX'));
  }, []);

  // useEffect(() => {
  //   if (user?.guid && tenant?.guid) {
  //     initUserForTracking(user, tenant.guid);
  //   }
  // }, [user, tenant]);

  useEffect(() => {
    checkApiHealth();
    if (isAuthorized && client) {
      dispatch(frontoffice.notifications.actions.getNotifications({ offset: 0, pageSize: 4, typeNotifications: notificationsTypes.SOCIAL }));
      dispatch(
        frontoffice.notifications.actions.getNotificationsToDo({
          offset: 0,
          pageSize: 4,
          typeNotifications: notificationsTypes.TODO,
        })
      );
      dispatch(frontoffice.notifications.actions.getNotificationsUnread());
      dispatch(frontoffice.notifications.actions.getNotificationsUnreadToDo());
      dispatch(frontoffice.learningObjectives.actions.getLearningObjectives());
      dispatch(frontoffice.educationLevels.actions.getEducationLevels({ country, client: client }));
      dispatch(coreEntities.systemSettings.actions.getSystemSettings());
    }
    if (client) {
      dispatch(frontoffice.langs.actions.getLangScopes());
    }

    // dispatch(contentsCourses.actions.getCourses());
  }, [isAuthorized, client]);

  useEffect(() => {
    if (!lms) {
      dispatch(coreEntities.organization.actions.getOrganization());
      dispatch(frontoffice.langs.actions.getLangs({ isFilter: true }));
    }
  }, [lms]);

  useEffect(() => {
    if (client && isAuthorized) {
      let account = oauthAccounts?.filter((acc) => acc.provider !== '');
      let schoolGuid = null;
      let schoolName = null;
      if (user.schools && user.schools.length > 0) {
        schoolGuid = user.schools[0].guid;
        schoolName = user.schools[0].name;
      }
      amplitude.getInstance().init(clientIdAmplitude[window.location.origin], user.guid);
      amplitude.getInstance().setUserProperties({
        client: client,
        role: user.role_name,
        source: isTeams() ? 'MS Teams' : user.source,
        provider: account && account.length > 0 ? account[0].provider : 'user and pass',
        schoolGuid: schoolGuid,
        schoolName: schoolName,
      });
    }
  }, [client, isAuthorized]);

  if (lastLocation && isAuthorized) {
    routerHelpers.saveLastLocation(lastLocation);
  }

  async function checkApiHealth() {
    const response = await getApiHealth();
    if (response?.status !== 200) {
      history.push('/error');
    }
  }

  const theme = useSelector((store) => frontoffice.ui.selectors.getTheme(store));
  const userRoleGuid = useSelector((state) => auth.selectors.getUserRoleGuid(state));
  const language = useSelector((state) => coreEntities.i18n.selectors.getLanguage(state));

  function checkIfCallbackTokenExists() {
    const path = window.location.pathname;
    // We check this in case it comes from the backoffice and we are already logged in with another user
    if (path.includes('/auth/login/')) {
      const splitted = path.split('/');
      callbackToken = splitted.length > 4 ? splitted[splitted.length - 2] : splitted[splitted.length - 1];
    }
    if (path === '/auth/login') {
      callbackToken = null;
    }
  }

  //EXAMPLE OF RESPONSE
  //window.open(`${BASE_URL_}auth/login/${response.data.data.token}/course&${data.guid}&program`);
  //auth/login/${token}/resources`)
  function redirectFromLoginWithToken() {
    const path = window.location.pathname;
    if (path.startsWith('/auth/login/')) {
      const splitted = path.split('/');
      if (splitted.length > 4) {
        const newPath = '/' + splitted[splitted.length - 1].replaceAll('&', '/');
        return newPath;
      }
    }
    return '/home';
  }

  useEffect(() => {
    if (isTeams()) {
      microsoftTeams.app.initialize().then(() => {
        microsoftTeams.app.getContext().then((context) => {
          setIsTeamsDark(context.app.theme === 'dark');
        });
        microsoftTeams.pages.getConfig().then((pageConfig) => {
          if (pageConfig.contentUrl) {
            setTeamsContentUrl(pageConfig.contentUrl.replace(window.location.origin, ''));
          }
        });
      });
    }
  }, []);

  const renderRoutes = (routes) => {
    return routes.map((route, index) => (
      <Route exact key={route.props || index} path={route.path} render={(props) => <route.component {...props} {...route.props} />} />
    ));
  };

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <div
      className={clsx(`theme-${theme}`, 'app-content', `lang-${language}`, {
        'app-content--publisher': isEditorial(userRoleGuid),
        'app-content--student': isStudent(userRoleGuid),
        'app-content--teacher': isTeacher(userRoleGuid),
        'app-content--msteams': isTeams(),
        'app-content--msteams-dark': isTeamsDark,
        'app-content--native-app': isNativeAppWebview(),
      })}
    >
      <LayoutContextProvider history={history} menuConfig={{}}>
        {userRoleGuid && isEditorial(userRoleGuid) && <BarMenuContainer />}
        <Switch>
          {renderRoutes(externalRoutes)}

          {!isAuthorized && (
            /* Redirect to `/auth` when user is not authorized */
            <Switch>
              <Route path={ROUTES.AUTH_LOGIN_TOKEN} component={TokenCallback} />
              <Route path={ROUTES.AUTH_LOGIN} component={LoginPage} />
              <Route path={ROUTES.SIGNUP} component={SignUpPage} />
              <Route path={ROUTES.RECOVER_PASSWORD} component={RecoverPasswordPage} />
              {/* Only redirect if not processing MSAL authentication */}
              {inProgress === InteractionStatus.None && <Redirect to={ROUTES.AUTH_LOGIN} />}
            </Switch>
          )}

          {((isAuthorized && !isChangePassword && license) || roleUser === 'R04') && (
            <Switch>
              <Redirect exact from="/" to={ROUTES.HOME} />
              {isTeams() && <Redirect exact from="/" to={teamsContentUrl} />}
              {isTeams() && <Redirect exact from={ROUTES.HOME} to={teamsContentUrl} />}
              {isLocalhost() && renderRoutes(localhostRoutes)}
              {renderRoutes(privateRoutes)}
              <Redirect from={ROUTES.AUTH_LOGIN_TOKEN} to={redirectFromLoginWithToken()} />
              <Redirect from={ROUTES.RECOVER_PASSWORD} to={ROUTES.HOME} />
              <Redirect from={ROUTES.AUTH} to={ROUTES.HOME} />
              <Redirect from={ROUTES.TAB} to={ROUTES.HOME} />
              <Route path="*" component={NotFound} />
            </Switch>
          )}

          {isAuthorized && isChangePassword && (
            <Switch>
              <Route path="/reset-password" component={ResetPasswordPage} />
              <Redirect to="/reset-password" />
            </Switch>
          )}

          {isAuthorized && !license && (
            <Switch>
              <Route path={ROUTES.LICENSE} component={AddLicense} />
              <Redirect to={ROUTES.LICENSE} />
            </Switch>
          )}
        </Switch>
      </LayoutContextProvider>
    </div>
  );
});
